import { createContext, Suspense, useState } from "../hooks/stateHooks"
import React from "../hooks/reactImport"
import { useDispatch } from "react-redux"
import { setCaptchaResponse } from "../redux/slices/states"
const GoogleReCaptchaProvider = React.lazy(() =>
  import("react-google-recaptcha-v3").then(module => {
    return { default: module.GoogleReCaptchaProvider }
  })
)

const GoogleReCaptcha = React.lazy(() =>
  import("react-google-recaptcha-v3").then(module => {
    return { default: module.GoogleReCaptcha }
  })
)
export const PageStateContext = createContext()

export const ProviderAPI = ({ children, value }) => {
  const [state, setState] = useState(value)

  return (
    <PageStateContext.Provider value={{ state, setState }}>
      {children}
    </PageStateContext.Provider>
  )
}

export const GoogleRecaptchContext = createContext(null)
export default function RecaptchaContext({ children, captchaSiteKey }) {
  const dispatch = useDispatch()
  var verifyCallba = function (response) {
    dispatch(setCaptchaResponse(response))
  }
  return (
    <Suspense>
      {captchaSiteKey ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={captchaSiteKey}
          scriptProps={{
            async: false, // optional, default to false,
            defer: true, // optional, default to false
            appendTo: "head", // optional, default to "head", can be "head" or "body",
            nonce: undefined, // optional, default undefined
          }}
        >
          <GoogleReCaptcha onVerify={verifyCallba} />
          <GoogleRecaptchContext.Provider>
            {children}
          </GoogleRecaptchContext.Provider>
        </GoogleReCaptchaProvider>
      ) : (
        <GoogleRecaptchContext.Provider>
          {children}
        </GoogleRecaptchContext.Provider>
      )}
    </Suspense>
  )
}

// const PageStateContext = createContext(null)
// export default PageStateContext
