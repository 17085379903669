import { Provider } from "react-redux"
import store from "./src/redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import RecaptchaContext from "./src/components/PageStateContext"

const React = require("react")
export const wrapPageElement = ({ element, props, location }) => {
  let persistor = persistStore(store)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RecaptchaContext captchaSiteKey={props?.pageContext?.captchaSiteKey}>
          {element}
        </RecaptchaContext>
      </PersistGate>
    </Provider>
  )
}
